import { axiosInstance } from 'plugin/axios';
import * as apiUrl from '../constant/urls';
import { DEFAULT_PAGE_SIZE, REACT_APP_ADMIN_API_ENDPOINT, REACT_APP_COMMON_ENDPOINT } from 'constant/AppConfig';

const API_ENDPOINT = REACT_APP_ADMIN_API_ENDPOINT;
const COMMON_ENDPOINT = REACT_APP_COMMON_ENDPOINT;

// =================== LOGIN SERVICE  =========================

const API_login = `${API_ENDPOINT}${apiUrl.loginUrl}`;
const API_verifyLoginOtp = `${API_ENDPOINT}${apiUrl.verifyLoginOtpUrl}`;
const API_resendLoginOtp = `${API_ENDPOINT}${apiUrl.resendLoginOtpUrl}`;
const API_resetPassword = `${API_ENDPOINT}${apiUrl.resetPasswordUrl}`;
const API_verifyToken = `${API_ENDPOINT}${apiUrl.verifyTokenUrl}`;
const API_updatePassword = `${API_ENDPOINT}${apiUrl.updatePasswordUrl}`;
const API_logout = `${API_ENDPOINT}${apiUrl.logoutUrl}`;

// ====================== USER DETAILS SERVICE ========================
const API_userDetails = `${COMMON_ENDPOINT}${apiUrl.getUserDetailsUrl}`;

// ====================== TSR USER SERVICE============================
const API_trsUserListings = `${API_ENDPOINT}${apiUrl.trsUserListingsUrl}`;
const API_AddTRSUser = `${API_ENDPOINT}${apiUrl.addTRSUserUrl}`;
const API_UploadProfileImage = `${COMMON_ENDPOINT}${apiUrl.uploadProfileImageUrl}`;
const API_verifyNewUserToken = `${API_ENDPOINT}${apiUrl.verifyNewUserTokenUrl}`;
const API_updateNewUserPassword = `${API_ENDPOINT}${apiUrl.updateNewUserPasswordUrl}`;
const API_tsrResendInviteNonAccepting = `${API_ENDPOINT}${apiUrl.tsrResendInviteNonAcceptingUrl}`;

// ========================== DASHBOARD SERVICE ========================
const API_dashboardStatics = `${API_ENDPOINT}${apiUrl.API_dashboardStaticsUrl}`;
const API_nonAcceptingUsers = `${API_ENDPOINT}${apiUrl.API_nonAcceptingUsersUrl}`;
const API_resendInviteNonAccepting = `${API_ENDPOINT}${apiUrl.API_resendInviteNonAcceptingUrl}`;
const API_usersByLocation = `${API_ENDPOINT}${apiUrl.API_usersByLocationUrl}`;

// =========================== COMPANY ============================
const API_companyListings = `${API_ENDPOINT}tenants/${apiUrl.companyListingsUrl}`;
const API_archiveCompany = `${API_ENDPOINT}tenants`;
const API_deleteCompanyForAdmin = `${API_ENDPOINT}tenants`;
const API_deleteCompanyForSuperAdmin = `${API_ENDPOINT}${apiUrl.deleteCompanyForSuperAdminUrl}`;
const API_restoreCompany = `${API_ENDPOINT}tenants`;
const API_tenantApproval = `${API_ENDPOINT}tenants`;
const API_companyDetails = `${API_ENDPOINT}${apiUrl.companyDetailsUrl}`;
const API_createCompany = `${API_ENDPOINT}${apiUrl.createCompanyUrl}`;
const API_updateCompany = `${API_ENDPOINT}${apiUrl.updateCompanyUrl}`;
const API_companyWiseUserListings = `${API_ENDPOINT}${apiUrl.companyWiseUsersListings}`;
const API_tenantUserFormDetail = `${API_ENDPOINT}${apiUrl.tenantUserFormDetailUrl}`;
const API_tenantUsers = `${API_ENDPOINT}${apiUrl.tenantUsersUrl}`;
const API_bulkImportTenantUser = `${API_ENDPOINT}${apiUrl.bulkImportTenantUserUrl}`;
const API_remindToLogin = `${API_ENDPOINT}${apiUrl.remindToLoginUrl}`;

// ----------------------------- LOGIN ---------------------------------------

// POST @login API
// @params email, password
export const loginUser = (creds) => {
  return axiosInstance.post(`${API_login}`, creds, { withCredentials: true });
};

// POST @verifyLoginOtp API
// @params mobile_number, user_otp
export const verifyLoginOtp = (params) => {
  return axiosInstance.post(`${API_verifyLoginOtp}`, params, { withCredentials: true });
};

// POST @resendLoginOtp API
// @params mobile_number, user_otp
export const resendLoginOtp = (params) => {
  return axiosInstance.post(`${API_resendLoginOtp}`, params);
};

//POST @resetPassword API
// @params email
export const resetPassword = (params) => {
  return axiosInstance.post(`${API_resetPassword}`, params);
};

//GET @verifyToken API - for verify reset password token
// @params token
export const verifyToken = (token) => {
  return axiosInstance.get(`${API_verifyToken}?token=${token}`);
};

// GET @updatePassword API
// @params email, password, confirm_password
export const updatePassword = (params) => {
  return axiosInstance.post(`${API_updatePassword}`, params);
};

// POST @logoutUser API
// @params
export const logoutUser = () => {
  return axiosInstance.post(`${API_logout}`);
};

// ---------------------------- USER DETAIL ----------------------------------

// GET @getUserDetails API
// @params email, password, confirm_password
export const getUserDetails = (id) => {
  return axiosInstance.get(`${API_userDetails}`);
};

// ---------------------------------- TSR USER ------------------------------
// GET @getTRSUsers API
export const getTRSUsersList = (status = 'active', ordering = '', ordered = '', search = '', page_size = DEFAULT_PAGE_SIZE, page = 1) => {
  return axiosInstance.get(
    `${API_trsUserListings}?user_status=${status}&ordering=${ordering}&ordered=${ordered}&search=${search}&page_size=${page_size}&page=${page}`
  );
};

// POST @AddTRSUser API
//
export const AddTRSUser = (params) => {
  return axiosInstance.post(`${API_AddTRSUser}`, params);
};

// POST @UploadProfileImage API
// form data @params profile_image
export const uploadProfileImage = (file, imgType, avatarType) => {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('image_type', imgType);
  formData.append('avatar_type', avatarType);

  return axiosInstance.post(`${API_UploadProfileImage}`, formData);
};

// GET @GetTRSUserById API
export const getTRSUserById = (id) => {
  return axiosInstance.get(`${API_AddTRSUser}/${id}`);
};

// GET @EditTRSUser API
// params @id
export const editTRSUser = (id, params) => {
  return axiosInstance.put(`${API_AddTRSUser}/${id}`, params);
};

// GET @DeleteTRSUser API
export const deleteTRSUser = (id) => {
  return axiosInstance.delete(`${API_AddTRSUser}/${id}`);
};

// tsr new
//GET @verifyNewUserToken API - for verify reset password token
// @params token
export const verifyNewUserToken = (token) => {
  return axiosInstance.get(`${API_verifyNewUserToken}?token=${token}`);
};

// GET @updateNewUserPassword API
// @params email, password, confirm_password
export const updateNewUserPassword = (params) => {
  return axiosInstance.post(`${API_updateNewUserPassword}`, params);
};

// GET @tsrResendInviteNonAccepting API
// @params user_id
export const tsrResendInviteNonAccepting = (params) => {
  return axiosInstance.post(`${API_tsrResendInviteNonAccepting}`, params);
};

// ------------------------ DASHBOARD --------------------------
// GET @dashboardStatics API
// @params stat_type, date_range
export const dashboardStatics = (statType, dateRange) => {
  return axiosInstance.get(
    `${API_dashboardStatics}?stat_type=${statType}&date_range=${dateRange ? `${dateRange?.start},${dateRange?.end}` : ''}`
  );
};

// GET @getNonAcceptingUsers API
// @params ordering, ordered=asc/desc, search, status=inactive, page_size
export const getNonAcceptingUsers = (ordering = '', ordered = '', search = '', status = '', page_size = DEFAULT_PAGE_SIZE, page = 1) => {
  return axiosInstance.get(
    `${API_nonAcceptingUsers}?ordering=${ordering}&ordered=${ordered}&search=${search}&status=${status}&page_size=${page_size}&page=${page}`
  );
};

// GET @resendInviteNonAccepting API
// @params user_id
export const resendInviteNonAccepting = (params) => {
  return axiosInstance.post(`${API_resendInviteNonAccepting}`, params);
};

// GET @usersByLocation API
// @params date_range
export const usersByLocation = (dateRange) => {
  return axiosInstance.get(`${API_usersByLocation}?date_range=${dateRange ? `${dateRange?.start},${dateRange?.end}` : ''}`);
};

// ------------------------ COMPANY -----------------------------------

// get @companyListing API
// @params status
export const companyListings = (ordering = '', ordered = '', search = '', status = '', page_size = DEFAULT_PAGE_SIZE, page = 1) => {
  return axiosInstance.get(
    `${API_companyListings}?ordering=${ordering}&ordered=${ordered}&search=${search}&status=${status}&page_size=${page_size}&page=${page}`
  );
};

// get @companyDetails API
// @params id
export const companyDetails = (id, isDetail = false) => {
  return axiosInstance.get(`${API_companyDetails}/${id}?company_detail=${isDetail}`);
};

// delete @deleteCompanyForAdmin API
// @params id
export const deleteCompanyForAdmin = (id) => {
  return axiosInstance.delete(`${API_deleteCompanyForAdmin}/${id}/${apiUrl.deleteCompanyForAdminUrl}`);
};

// delete @deleteCompanyForSuperAdmin API
// @params id
export const deleteCompanyForSuperAdmin = (id) => {
  return axiosInstance.delete(`${API_deleteCompanyForSuperAdmin}/${id}`);
};

// POST @deleteCompanyForSuperAdmin API
// @params id
export const archiveCompany = (id) => {
  return axiosInstance.post(`${API_archiveCompany}/${id}/${apiUrl.archiveCompanyUrl}`);
};

// POST @restoreCompany API
// @params id
export const restoreCompany = (id) => {
  return axiosInstance.post(`${API_restoreCompany}/${id}/${apiUrl.restoreCompanyUrl}`);
};

// POST @tenantApproval API
// @params approval
export const tenantApproval = (id, params) => {
  return axiosInstance.post(`${API_tenantApproval}/${id}/${apiUrl.tenantApprovalUrl}`, params);
};

// POST @addCompany API
// @params
export const addCompany = (params) => {
  return axiosInstance.post(`${API_createCompany}`, params);
};

// PUT @updateCompany API
// @params
export const updateCompany = (id, params) => {
  return axiosInstance.put(`${API_updateCompany}/${id}`, params);
};

// GET @getCompanyWiseUser API
// @params tenant, ordering, ordered, search, status, page_size, page
export const getCompanyWiseUsers = (
  tenant = '',
  ordering = '',
  ordered = '',
  search = '',
  status = '',
  page_size = DEFAULT_PAGE_SIZE,
  page = 1
) => {
  return axiosInstance.get(
    `${API_companyWiseUserListings}?tenant=${tenant}&ordering=${ordering}&ordered=${ordered}&search=${search}&status=${status}&page_size=${page_size}&page=${page}`
  );
};

// GET @getTenantUserFormDetail API
// @params id
export const getTenantUserFormDetails = (id, user_id) => {
  return axiosInstance.get(`${API_tenantUserFormDetail}/${id}/user-form?user_id=${user_id}`);
};

// POST @archiveCompanyUser API
// @params id
export const archiveCompanyUser = (id) => {
  return axiosInstance.post(`${API_tenantUsers}/${id}/archive`);
};

// POST @restoreCompanyUser API
// @params id
export const restoreCompanyUser = (id) => {
  return axiosInstance.post(`${API_tenantUsers}/${id}/restore`);
};

// DELETE @deleteCompanyUser API
// @params id
export const deleteCompanyUser = (id) => {
  return axiosInstance.delete(`${API_tenantUsers}/${id}`);
};

// POST @addCompanyUser API
// @params
export const addCompanyUser = (params) => {
  return axiosInstance.post(`${API_tenantUsers}`, params);
};

// GET @getCompanyUserById API
// @params id
export const getCompanyUserById = (id) => {
  return axiosInstance.get(`${API_tenantUsers}/${id}`);
};

// PUT @editCompanyUser API
// @params id
export const editCompanyUser = (id, params) => {
  return axiosInstance.put(`${API_tenantUsers}/${id}`, params);
};

// POST @bulkImportTenantUser API
// file, tenantId
export const bulkImportTenantUser = (params) => {
  return axiosInstance.post(`${API_bulkImportTenantUser}`, params, {
    responseType: 'blob'
  });
};

// POST @remindToLogin
// @param user_id
export const remindToLogin = (tenantId, ids) => {
  return axiosInstance.post(`${API_remindToLogin}`, {
    tenant_id: tenantId,
    user_ids: ids
  });
};

export const apiRequest = {
  loginUser,
  verifyLoginOtp,
  resendLoginOtp,
  resetPassword,
  verifyToken,
  updatePassword,
  getUserDetails,
  getTRSUsersList,
  AddTRSUser,
  uploadProfileImage,
  getTRSUserById,
  editTRSUser,
  deleteTRSUser,
  verifyNewUserToken,
  updateNewUserPassword,
  dashboardStatics,
  getNonAcceptingUsers,
  resendInviteNonAccepting,
  tsrResendInviteNonAccepting,
  companyListings,
  deleteCompanyForAdmin,
  deleteCompanyForSuperAdmin,
  archiveCompany,
  restoreCompany,
  tenantApproval,
  companyDetails,
  usersByLocation,
  addCompany,
  updateCompany,
  getCompanyWiseUsers,
  getTenantUserFormDetails,
  archiveCompanyUser,
  deleteCompanyUser,
  restoreCompanyUser,
  addCompanyUser,
  getCompanyUserById,
  editCompanyUser,
  bulkImportTenantUser,
  remindToLogin
};
